@import "minima";

hr.dotted {
  border-top: 1px dotted $grey-color-light;
  margin-bottom: 30px;
}

.recent-title {
  @include relative-font-size(1.5);
  margin-top: 10px;
}

.cards-container {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  max-width: 200px;
  padding: 0 50px;
  margin: 10px;
  border: 1px solid $grey-color-light;
  flex: 1 0 auto;
  height: auto;

  .title {
    font-weight: bold;
  }

  .description {
    color: $text-color;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;

    .footer-item {
      margin-bottom: 20px;
    }

    .footer-item--tag {
      background-color: $brand-color;
      border: solid transparent;
      border-radius: 30px;
      width: 80px;
      height: 20px;
      color:$background-color;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  a a:hover, a:visited, a:link, a:active {
    text-decoration: none;
  }
}

.card:before {
  content: '';
  display: block;
  padding-top: 10%;
}

.card:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.see-all {
  text-align: center;
}

ul {
  list-style-type: circle;
}

.categories {
  font-size: 14px;
  color: $grey-color;
}

.item-tag {
  background-color: $brand-color;
  border: solid transparent;
  border-radius: 30px;
  padding: 0 5px;
  font-size: 10px;
  color:$background-color;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
}
